import React, { useContext } from "react"

import Layout from "components/layout"

import { Section, Container } from "components/anti/grid/grid"
import { Button } from "components/anti/buttons/buttons"

//Load Context
import { LangContext } from "../context/lang-context"

const NotFoundPage = () => {
  const { lang } = useContext(LangContext)

  return (
    <Layout>
      <Section className="h-400px content-center">
        <Container className="mw-md text-center">
          <h1>
            {lang === "EN" ? "Page not found" : "Halaman tidak ditemukan"}
          </h1>
          <p>
            {lang === "EN"
              ? "Sorry, the page you're looking for was not found."
              : "Maaf, halaman yang anda cari tidak ditemukan."}
          </p>
          <Button variant="primary" link="/">
            {lang === "EN" ? "Go to Home Page" : "Kembali ke halaman Home"}
          </Button>
        </Container>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
